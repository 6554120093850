import React from "react";
import Layout from "../components/layout";
import { Card, Col, Row, Image, ListGroup, ListGroupItem } from "react-bootstrap";
import DepositoImg from "../images/deposito.jpg"
import Seo from "../components/seo";

const Investment = () => (
    <Layout>
        <Seo title="Inversiones a plazo fijo" description="Describe inversiones a plazo fijo en CoopGestión" />
        <Row>
            <Col>
                <div className="jumbotron bg-secondary text-white">
                    <h1 className="display-4 jumbotron-title">DEPÓSITO A PLAZO FIJO</h1>
                    <p className="lead">
                        Invierte con nosotros! Haz crecer tu dinero.
                    </p>
                </div>
            </Col>
        </Row>
        <Row className="my-3">
            <Col sm className="m-auto py-1 text-center">
                <Image src={DepositoImg} alt="Depósito a plazo fijo" fluid />
            </Col>
            <Col sm className="m-auto">
                <Card>
                    <Card.Header>Depósito a plazo fijo</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            Con las mejores tasas de interés del mercardo.<br /> Invierte a 30, 60
                            90, 120, 180 o 360 días.<br />No se requiere ser socio
                        </Card.Text>
                        <Card.Title>
                            Requisitos
                        </Card.Title>
                        <ListGroup variant="flush">
                            <ListGroupItem>&#x2022;  Copia de cédula de identidad</ListGroupItem>
                            <ListGroupItem>&#x2022;  Copia de papeleta de votación</ListGroupItem>
                            <ListGroupItem>&#x2022;  Planilla de servicio básico</ListGroupItem>
                        </ListGroup>
                        {/* <Card.Text>
                            <ul>
                                <li>Copia cédula de identidad</li>
                                <li>Copia papeleta de votación</li>
                                <li>Planilla de servicio básico</li>
                            </ul>
                        </Card.Text> */}
                    </Card.Body>
                </Card>
            </Col>
        </Row>

    </Layout>
);

export default Investment;